






































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { ColumnConfig } from '@/components/table/types'

@Component
export default class withDraw extends Vue {
    loading = false;
    activitiesVisable = false;
    rewordVisible = false;
    total = 0;
    moneyTotal = 0;
    couponTotal = 0;
    communityData = [];
    current = 1;
    formInline = {
      activityId: '',
      number: '',
      state: '',
      userName: ''
    };

    // 活动类型下拉查询
    activitieselection:any=[]

    srcList=[]

    // 多选后的数据
    selectVal = [];
    // 校验后的中奖id
    rewordId:any = [];
    // 文件上传
    actionPath = `${process.env.VUE_APP_FILE_URL}/akfile/do/upload?project=system&code=file&rename=0`;
    // 文件回显
    fileURL = `${process.env.VUE_APP_FILE_URL}`;
    downloadUrl = `${process.env.VUE_APP_DOWNLOAD_URL}`;
    fileList: any = [];
    downloadPath = '';
    dialogImageUrl = '';
    dialogVisible = false;
    disabled = false;
    // 表格数据初始化
    tableData = [];
    activeTableData = [];

    // 奖励表单
    rewordFormData:any = {
      filePath: '',
      issueMoney: '',
      issueTime: '',
      remark: '',
      ids: []
    };

    integerPoint (rule:unknown, value:string, callback:any) {
      const reg = /^\+?(\d*\.\d{2})$/
      if (!reg.test(value)) {
        callback(new Error('输入金额必须为大于零的数，且精确到两位小数！'))
      } else {
        callback()
      }
    }

    // 发方奖励自定义校验规则
    rulesOption = {
      issueMoney: [
        { required: true, message: '请填写发放金额', trigger: 'change' },
        {
          validator: this.integerPoint
        }
      ],
      issueTime: [
        { required: true, message: '请选择发放时间', trigger: 'change' }
      ],
      filePath: [{ required: true, message: '请上传资料', trigger: 'change' }]
    };

    column: Array<ColumnConfig> = [
      {
        type: 'selection',
        fixed: 'left'
      },
      {
        label: '用户名',
        code: 'userName',
        scopedSlots: 'nickName',
        fixed: 'left'
      },
      {
        label: '用户ID',
        code: 'userId',
        width: '180px'
      },
      {
        label: '奖励类型',
        code: 'issueType',
        scopedSlots: 'issueType',
        width: '180px'
      },
      {
        label: '中奖金额/元',
        code: 'winningMoney',
        width: '180px'
      },
      {
        label: '中奖时间',
        code: 'winningTime',
        width: '180px'
      },
      {
        label: '活动类型',
        code: 'activityName',
        width: '180px'
      },
      {
        label: '发放状态',
        code: 'state',
        scopedSlots: 'state',
        width: '180px'
      },
      {
        label: '发放编号',
        code: 'issueNumber',
        width: '180px'
      },
      {
        label: '发放日期',
        code: 'issueTime',
        width: '180px'
      },
      {
        label: '发放金额/元',
        code: 'issueMoney',
        width: '180px'
      },
      {
        label: '中奖编号',
        code: 'number',
        width: '180px'
      },
      {
        label: '活动编号',
        code: 'activityNumber',
        width: '180px'
      },
      {
        label: '资料',
        code: 'filePath',
        scopedSlots: 'filePath',
        width: '180px'
      },
      {
        label: '备注',
        code: 'remark',
        width: '180px'
      }
    ];

    //   活跃度展示
    activColumn: Array<ColumnConfig> = [
      {
        label: '活动编号',
        code: 'updateAt'
      },
      {
        label: '开始时间',
        code: 'status'
      },
      {
        label: '结束时间',
        code: 'status'
      }
    ];

    // 提现列表
    withdrawColumn: Array<ColumnConfig> = [
      {
        label: '发放时间',
        code: 'updateAt'
      },
      {
        label: '发放金额',
        code: 'status'
      }
    ];

    // 查看参与活动
    activitiesColumn: Array<ColumnConfig> = [
      {
        label: '活动名称',
        code: 'updateAt'
      },
      {
        label: '时间',
        code: 'status'
      }
    ];

    ellipsisStr<T> (str: T, limit = 15): T | string {
      if (typeof str === 'string' && str && str.length > limit) {
        return str.substring(0, limit) + '...'
      } else {
        return str
      }
    }

    toMore (id: number) {
      // '/accountinfo',query:{id:id}
      this.$router.push({ path: '/accountinfo', query: { id: String(id) } })
    }

    // 点击活动编号，查看活动类容
    async onShowActivities (activityNumber: any) {
      this.activitiesVisable = true
      const res = await this.$Get(this.$Api.issueActivity + activityNumber)
      this.activeTableData = res.data
    }

    onSearch (): void {
      this.fetch()
    }

    onReSet () {
      this.formInline = {
        activityId: '',
        number: '',
        state: '',
        userName: ''
      }
      this.fetch()
    }

    onReword () {
      this.rewordId = []
      //   判断是否选择用户
      if (this.selectVal.length === 0) {
        this.$message({
          message: '请先选择要发放奖励的用户！',
          type: 'warning'
        })
      } else {
        //   判断用户是否一致
        let flag = true
        const newArray = this.selectVal.filter((el:any) => {
          this.selectVal.map((item:any) => {
            if (el.userId !== item.userId) {
              flag = false
            }
          })
        })
        if (!flag) {
          this.$message({
            message: '选择用户不一致，请重新选择！',
            type: 'warning'
          })
        } else {
          //   过滤用户发放状态
          const newSet = this.selectVal.filter((el:any) => {
            if (el.state === 1 || el.state === 2) {
              return el
            }
          })
          if (newSet.length !== 0) {
            this.$message({
              message: '选择信息的发放状态不能为已发放或超期作废，请重新选择！',
              type: 'warning'
            })
            return false
          } else {
            this.selectVal.forEach((el:any) => {
              this.rewordId.push(el.id)
            })
            this.rewordVisible = true
          }
        }
      }
    }

    // 导出
    onExport () {
      const fileName = '提现列表.xls'
      const link = document.createElement('a')
      link.href = this.downloadUrl + this.$Api.issueExport
      link.download = fileName
      link.click()
    }

    async fetch () {
      this.loading = true
      const [r1, r2] = await Promise.all([
        this.$Get(this.$Api.issueListPage, {
          pageSize: 10,
          current: this.current,
          ...this.formInline
        }),
        this.$Get(this.$Api.issueGetTotal)
      ])
      this.moneyTotal = r2.data.moneyTotal
      this.tableData = r1.data.records.map((el: any) => {
        let fullUrl = ''
        fullUrl = this.fileURL + '/' + el.filePath
        el.fileURL = this.fileURL
        const previewList:Array<any> = []
        previewList.push(fullUrl)
        el.previewList = previewList
        return el
      })
      this.total = r1.data.total
      this.loading = false
    }

    changePagination (current: number) {
      this.current = current
      this.fetch()
    }

    selectionChange (val: any) {
      this.selectVal = Array.from(val)
    }

    async mounted () {
      this.fetch()
      const res = await this.$Get(this.$Api.activityList, {
        pageSize: 100,
        current: this.current
      })
      res.data.forEach((el:any) => {
        this.activitieselection.push(el)
      })
    }

    handlePictureCardPreview (file: any) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }

    handleRemove () {
      this.fileList = this.fileList.splice(1, 1)
    }

    async submitForm () {
      const me = this as any
      if (this.fileList.length === 0) {
        this.$message({
          message: '发放资料不能为空，请先上传！',
          type: 'warning'
        })
        return
      }
      this.rewordFormData.filePath = this.fileList[0].response[0]
      me.$refs.ruleForm.validate((valid: any) => {
        if (valid) {
          this.rewordFormData.ids = this.rewordId.join(',')
        } else {
          return false
        }
      })
      console.log(this.rewordFormData)
      await this.$Post(this.$Api.doIssue, {
        ...this.rewordFormData
      })
      this.$message({
        showClose: true,
        message: '发放成功',
        type: 'success'
      })
      this.rewordVisible = false
      this.fetch()
    }

    // 关闭表单
    closeDialog () {
      (this as any).$refs.ruleForm.resetFields()
      this.rewordFormData.filePath = ''
      this.handleRemove()
    }

    resetForm () {
      (this as any).$refs.ruleForm.resetFields()
      this.handleRemove()
    }

    // 文件列表改变
    handleChange (file: any, fileList: any) {
      this.fileList = fileList.slice(-3)
    }

    // 发方奖励表单金额自动记为两位小数
    issueMoneyChange () {
      this.rewordFormData.issueMoney = parseFloat(this.rewordFormData.issueMoney).toFixed(2)
    }
}
